<template>
  <div class="container-page big">
    <HeaderBlock
      title="ПЕРЕЧЕНЬ ЕДИНЫХ МЕР ТАМОЖЕННО-ТАРИФНОГО РЕГУЛИРОВАНИЯ, ПРИМЕНЯЕМЫХ НА&nbsp;ВРЕМЕННОЙ ОСНОВЕ"
      :modifiedRangeData="modifiedRangeData"
      :selectedIds="selectedIds"
      :changeLog="changeLog.isActive"
      modifiedRangeDataVisible
      @create="editCard(0)"
      @clear="clearFilter"
      @export="exportExcel"
      @import="importExcel"
      @editView="editView"
      @bulkEditing="bulkEditing"
      @changesPeriod="showModifiedRangeModal"
      @backRegistry="showRegistry"
      @onBackChangesOverPeriod="filterModified"
    />

    <div class="table-shadow">
      <div class="table-container-base table-container-base--sort js-table-arrow">
        <table v-if="!changeLog.isActive">
          <HeaderTable
            :modifiedRangeData="modifiedRangeData"
            :tableHeader="getTableHeaderSingle"
            @changeSorting="changeSorting"
            @changeFilter="changeFilter"
            @changeSelectAll="changeSelectAll"
            ref="headerTable"
          />
          <TableBody :itemsBody="page.items" :isLoading="isLoading">
            <tr v-for="item in page.items" :key="item.id" @dblclick="editCard(item.id)">
              <td :class="[modifiedRangeData.isActive ? 'column-icon-88' : 'column-icon']">
                <div class="wrap-icon">
                  <IconComponent
                    v-if="modifiedRangeData.isActive"
                    class="icon--full-svg"
                    @click="switchChangelog(item.id)"
                    name="time-redesign"
                  />
                  <IconComponent class="icon--full-svg" @click="editCard(item.id)" name="edit" />
                  <IconComponent class="icon--full-svg delete" @click="deleteCard(item.id)" name="delete-not-fill" />
                </div>
              </td>
              <td class="width-46"><input type="checkbox" v-model="selectedIds" :value="item.id" /></td>
              <td v-if="getVisibleItems.includes(tableIdColumns.year)" class="width-138">{{ item.year }}</td>
              <td v-if="getVisibleItems.includes(tableIdColumns.organName)" class="width-192">
                {{ item.organ?.name }}
              </td>
              <td v-if="getVisibleItems.includes(tableIdColumns.decisionDate)" class="width-202">
                {{ $momentFormat(item.decisionDate, 'DD.MM.YYYY') }}
              </td>
              <td v-if="getVisibleItems.includes(tableIdColumns.decisionNumber)" class="width-192">
                {{ item.decisionNumber }}
              </td>
              <td v-if="getVisibleItems.includes(tableIdColumns.initiator)" class="width-232">{{ item.initiator }}</td>
              <td v-if="getVisibleItems.includes(tableIdColumns.tnvedName)" class="width-352">
                {{ item.tnvedAlias }}
              </td>
              <td v-if="getVisibleItems.includes(tableIdColumns.tnvedNameBefore)" class="width-192">
                <div v-for="entry in item.singleTtrHistory" :key="entry.id">
                  {{ entry.tnvedBefore?.code ?? entry.tnvedNameBefore ?? '&mdash;' }}
                </div>
              </td>
              <td v-if="getVisibleItems.includes(tableIdColumns.rateBefore)" class="width-232">
                <div v-for="entry in item.singleTtrHistory" :key="entry.id">
                  {{ entry.rateBefore ?? '&mdash;' }}
                </div>
              </td>
              <td v-if="getVisibleItems.includes(tableIdColumns.tnvedNameAfter)" class="width-192">
                <div v-for="entry in item.singleTtrHistory" :key="entry.id">
                  {{ entry.tnvedAfter?.code ?? entry.tnvedNameAfter ?? '&mdash;' }}
                </div>
              </td>
              <td v-if="getVisibleItems.includes(tableIdColumns.rateAfter)" class="width-232">
                <div v-for="entry in item.singleTtrHistory" :key="entry.id">
                  {{ entry.rateAfter ?? '&mdash;' }}
                </div>
              </td>
              <td v-if="getVisibleItems.includes(tableIdColumns.applyDate)" class="width-226">
                <div v-for="entry in item.singleTtrHistory" :key="entry.id">
                  {{ entry.applyDateFrom ? `с ${$momentFormat(entry.applyDateFrom, 'DD.MM.YYYY')}` : '' }}
                  {{ entry.applyDateTo ? `по ${$momentFormat(entry.applyDateTo, 'DD.MM.YYYY')}` : '' }}
                </div>
              </td>
              <td v-if="getVisibleItems.includes(tableIdColumns.comment)" class="width-232">{{ item.comment }}</td>
              <td v-if="getVisibleItems.includes(tableIdColumns.industryNames)" class="width-192">
                {{ item.industryNames?.join('; ') }}
              </td>
              <td v-if="getVisibleItems.includes(tableIdColumns.prolongation)" class="width-192">
                {{ item.prolongation }}
              </td>
              <td v-if="getVisibleItems.includes(tableIdColumns.isActive)" class="width-192">
                {{ item.isActive ? 'действ.' : 'не действ.' }}
              </td>
              <td v-if="getVisibleItems.includes(tableIdColumns.moveDirection)" class="width-240">
                {{ item.moveDirection?.name }}
              </td>
            </tr>
          </TableBody>
        </table>
        <table v-else>
          <HeaderTable
            :modifiedRangeData="modifiedRangeData"
            :tableHeader="getTableHeaderSingle"
            @changeSorting="changeSortingChangeLog"
            @changeFilter="changeFilterChangeLog"
            ref="headerTable"
            changeLog
          />
          <TableBody :itemsBody="page.items" :isLoading="isLoading">
            <tr v-for="(item, index) in page.items" :key="item.id">
              <td>
                <div class="wrap-icon">
                  <IconComponent
                    @click="updateMemory(item)"
                    v-tooltip="'Восстановить запись'"
                    class="icon--full-svg"
                    name="restore"
                  />
                </div>
              </td>
              <td :class="['width-138', getClassStatus(item.actionId)]">{{ item.actionName }}</td>
              <td class="width-138">{{ $momentFormat(item.created, 'DD.MM.YYYY') }}</td>
              <td class="width-192">{{ item.userName }}</td>
              <td :class="['width-138', getClassChange(index, 'year')]">{{ item.entityDto.year }}</td>
              <td :class="['width-192', getClassChange(index, 'organ', { name: 'name' })]">
                {{ item.entityDto.organ?.name }}
              </td>
              <td :class="['width-202', getClassChange(index, 'decisionDate')]">
                {{ $momentFormat(item.entityDto.decisionDate, 'DD.MM.YYYY') }}
              </td>
              <td :class="['width-192', getClassChange(index, 'decisionNumber')]">
                {{ item.entityDto.decisionNumber }}
              </td>
              <td :class="['width-232', getClassChange(index, 'initiator')]">{{ item.entityDto.initiator }}</td>
              <td :class="['width-352', getClassChange(index, 'tnved', { name: 'name' })]">
                {{ item.entityDto.tnvedAlias }}
              </td>
              <td
                :class="[
                  'width-192',
                  getClassChange(index, 'singleTtrHistory', { name: 'tnvedNameBefore', array: true }),
                ]"
              >
                <div v-for="entry in item.entityDto.singleTtrHistory" :key="entry.id">
                  {{ entry.tnvedNameBefore ?? '&mdash;' }}
                </div>
              </td>
              <td
                :class="['width-232', getClassChange(index, 'singleTtrHistory', { name: 'rateBefore', array: true })]"
              >
                <div v-for="entry in item.entityDto.singleTtrHistory" :key="entry.id">
                  {{ entry.rateBefore ?? '&mdash;' }}
                </div>
              </td>
              <td
                :class="[
                  'width-192',
                  getClassChange(index, 'singleTtrHistory', { name: 'tnvedNameAfter', array: true }),
                ]"
              >
                <div v-for="entry in item.entityDto.singleTtrHistory" :key="entry.id">
                  {{ entry.tnvedNameAfter ?? '&mdash;' }}
                </div>
              </td>
              <td :class="['width-232', getClassChange(index, 'singleTtrHistory', { name: 'rateAfter', array: true })]">
                <div v-for="entry in item.entityDto.singleTtrHistory" :key="entry.id">
                  {{ entry.rateAfter ?? '&mdash;' }}
                </div>
              </td>
              <td :class="['width-226', getClassChange(index, 'singleTtrHistory')]">
                <div v-for="entry in item.entityDto.singleTtrHistory" :key="entry.id">
                  {{ entry.applyDateFrom ? `с ${$momentFormat(entry.applyDateFrom, 'DD.MM.YYYY')}` : '' }}
                  {{ entry.applyDateFrom && entry.applyDateTo ? '-' : '' }}
                  {{ entry.applyDateTo ? `по ${$momentFormat(entry.applyDateTo, 'DD.MM.YYYY')}` : '' }}
                </div>
              </td>
              <td :class="['width-232', getClassChange(index, 'comment')]">{{ item.entityDto.comment }}</td>
              <td :class="['width-192', getClassChange(index, 'industryNames')]">
                {{ item.entityDto.industryNames?.join('; ') }}
              </td>
              <td :class="['width-192', getClassChange(index, 'prolongation')]">
                {{ item.entityDto.prolongation }}
              </td>
              <td :class="['width-192', getClassChange(index, 'isActive')]">
                {{ item.entityDto.isActive ? 'действ.' : 'не действ.' }}
              </td>
              <td :class="['width-240', getClassChange(index, 'moveDirection', { name: 'name' })]">
                {{ item.entityDto.moveDirection?.name }}
              </td>
            </tr>
          </TableBody>
        </table>
      </div>
    </div>

    <PaginationRedesigned
      v-if="page.pagesTotal > 1 && !isLoading"
      :page-count="page.pagesTotal"
      :current-page="page.pageNumber"
      @change="onPageChange($event, false)"
      @more="onPageChange($event, true)"
    />

    <ModalComponent
      v-model="importModal"
      content-class="modal--new"
      title="Импорт файла"
      @close="closeModals('importModal')"
      headType="redesigned"
      bodyType="redesigned"
      @update:modelValue="closeModals('importModal')"
    >
      <ImportModal @doimport="doImport" @close="closeModals('importModal')" ref="importModal" />
    </ModalComponent>

    <ModalComponent
      v-model="importDataModal"
      :width="1000"
      content-class="modal--new"
      title="Импорт файла"
      @close="closeModals('importDataModal')"
      headType="redesigned"
      bodyType="redesigned"
    >
      <SingleTtrImportComponent :import-data="importData" @close="closeAndReload" :nn="nn" />
    </ModalComponent>

    <ModalComponent
      v-model="modifiedRangeData.showModal"
      title="Изменения за период"
      content-class="modal--new"
      @close="closeModifiedModal"
      headType="redesigned"
      bodyType="redesigned"
      @update:modelValue="closeModifiedModal"
    >
      <SingleTtrFilterModifiedComponent
        ref="modifiedRangeDataModal"
        @filter="filterModified"
        @close="closeModifiedModal"
      />
    </ModalComponent>

    <ModalComponent
      v-model="bulkEditingModal"
      content-class="modal--new"
      title="Массовое редактирование записей"
      headType="redesigned"
      bodyType="redesigned"
      @close="closeModals('bulkEditingModal')"
      @update:modelValue="closeModals('bulkEditingModal')"
    >
      <ContentPopupBulkEditing
        :selectedItems="selectedIds"
        :statusOptions="getStatusOptions"
        @closePopup="closeModals('bulkEditingModal')"
        @save="bulkEditingChange"
        ref="bulkEditingModal"
      />
    </ModalComponent>

    <ModalComponent
      v-model="editViewModal"
      content-class="modal--new"
      title="Настройка вида отображения"
      headType="redesigned"
      bodyType="redesigned"
      @close="closeModals('editViewModal')"
      @update:modelValue="closeModals('editViewModal')"
    >
      <ContentPopupEditView
        :listCheckbox="getTableHeaderSingle"
        @save="changeColumnTable"
        @closePopup="closeModals('editViewModal')"
        ref="editViewModal"
      />
    </ModalComponent>

    <!-- статус Массового редактирования и Настройка вида отображения -->
    <ModalInfo
      v-model="isOpenModalInfo"
      @closeModal="closeModalInfo"
      :titleModal="titleModal"
      :textModal="textModal"
      :modalIconName="modalIconName"
    />
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex';

  import ModalInfo from '@/common/components/redesigned/ModalInfo.vue';
  import PaginationRedesigned from '@/common/components/redesigned/PaginationRedesigned';
  import TableBody from '@/common/components/redesigned/TableBody';
  import Constants from '@/common/constants';
  import modalInfo from '@/common/mixins/modalInfo';
  import removeEmptyValuesFromObject from '@/common/mixins/removeEmptyValuesFromObject';
  import security from '@/common/mixins/security';
  import IconComponent from '@/common/ui/IconComponent';

  import ImportModal from '@/components/modals/ImportModal';
  import ModalComponent from '@/components/modals/ModalComponent';

  import Api from '../api/singlettr';
  import ContentPopupBulkEditing from '../components/ContentPopupBulkEditing';
  import ContentPopupEditView from '../components/ContentPopupEditView';
  import HeaderBlock from '../components/HeaderBlock';
  import HeaderTable from '../components/singleComponents/HeaderTable';
  import SingleTtrFilterModifiedComponent from '../components/SingleTtrFilterModifiedComponent';
  import SingleTtrImportComponent from '../components/SingleTtrImportComponent';
  import duplicateMethods from '../mixins/duplicateMethods';
  import {
    SINGLE_TTR_REGISTRY_ACTIONS_NAME,
    SINGLE_TTR_REGISTRY_GETTERS_NAME,
    SINGLE_TTR_REGISTRY_MUTATIONS_NAME,
  } from '../store/singleTtrRegistry/name.js';
  import { MODAL_INFO_MESSAGES, TABLE_ID_SINGLE } from '../utils/constants';

  export default {
    name: 'SingleTtrRegistry',
    components: {
      IconComponent,
      ModalComponent,
      ImportModal,
      SingleTtrImportComponent,
      SingleTtrFilterModifiedComponent,
      ModalInfo,
      PaginationRedesigned,
      ContentPopupBulkEditing,
      ContentPopupEditView,
      HeaderBlock,
      TableBody,
      HeaderTable,
    },
    mixins: [security, removeEmptyValuesFromObject, modalInfo, duplicateMethods],
    data() {
      return {
        page: {},
        isSecond: false,
        isLoading: false,
        nn: 1,
        selectedIds: [],
        importModal: false,
        importDataModal: false,
        importData: {},
        bulkEditingModal: false,
        editViewModal: false,
        filter: {},
        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
          isSecond: false,
          sortColumn: 0,
          sortOrder: 0,
        },
        modifiedRangeData: {
          isActive: false,
          showModal: false,
          filter: null,
          filterText: null,
        },
        tableIdColumns: TABLE_ID_SINGLE,
      };
    },
    computed: {
      ...mapGetters({
        getTableHeaderSingle: SINGLE_TTR_REGISTRY_GETTERS_NAME.getTableHeaderSingle,
      }),

      getVisibleItems() {
        return this.getTableHeaderSingle.filter((i) => i.visible).map((i) => i.id);
      },

      getFilterData() {
        let dataFilter = {};
        if (this.modifiedRangeData.isActive) {
          dataFilter = this.objectFormatter(this.modifiedRangeData.filter);
          if (dataFilter.tnvedId) dataFilter.tnvedIds = [dataFilter.tnvedId];
        } else {
          dataFilter = this.objectFormatter(this.filter);
          if (this.filter.isActive) {
            dataFilter.isActive = this.filter.isActive == 1;
          }
        }
        return dataFilter;
      },
    },
    created() {
      this.checkPermissionAndRedirect(Constants.Permissions.MeasureSingleTtriew);
      this.loadDictionaries();
      this.loadPage(false);
    },
    methods: {
      ...mapActions({
        changeParametersMulti: SINGLE_TTR_REGISTRY_ACTIONS_NAME.changeParametersMulti,
        findAuditLog: SINGLE_TTR_REGISTRY_ACTIONS_NAME.findAuditLog,
        update: SINGLE_TTR_REGISTRY_ACTIONS_NAME.update,
      }),

      ...mapMutations({
        updateListTableHeaderSingle: SINGLE_TTR_REGISTRY_MUTATIONS_NAME.updateListTableHeaderSingle,
      }),

      loadDictionaries() {
        this.getNSIMeasureOrganProtectedMarket();
        this.getNSIIndustry();
        this.getNSIMoveDirections();
      },

      loadPage(append) {
        this.isLoading = !append;

        Api.search({
          ...this.request,
          ...this.getFilterData,
        })
          .then((response) => {
            if (!append) {
              this.page = {};
              this.$nextTick(() => {
                this.page = response.data;
              });
            } else if (response.data) {
              this.page.pageNumber = response.data.pageNumber;
              this.page.items = this.page.items.concat(response.data.items);
            }
          })
          .finally(() => {
            this.isLoading = false;
          })
          .catch((error) => {
            console.error('this.loadPage:', error);
          });
      },

      onPageChange(pageNumber, addToExist) {
        this.request.pageNumber = pageNumber;
        if (this.changeLog.isActive) {
          this.loadChangeLog(addToExist);
        } else {
          this.loadPage(addToExist);
        }
      },

      exportExcel() {
        Api.exportExcel({ ...this.getFilterData, isSecond: this.isSecond });
      },

      editCard(id) {
        this.$router.push({ name: 'SingleTtrCard', params: { id: id, action: 'edit' } });
      },

      closeModals(type) {
        this[type] = false;
        if (this.$refs[type]) {
          this.$refs[type].reset();
        }
      },

      deleteCard(id) {
        Constants.alert.fire(Constants.confirmOptions.delete).then((res) => {
          if (res.isConfirmed) {
            Api.delete(id)
              .then(() => {
                Constants.alert.fire('Удаление', 'Запись удалена', 'success');
                this.loadPage();
              })
              .catch((error) => {
                Constants.alert.fire(
                  'Удаление',
                  error.response && error.response.data ? error.response.data : 'Запись не удалена.',
                  'error',
                );
              });
          }
        });
      },

      importExcel() {
        this.importModal = true;
      },

      doImport(file) {
        this.closeModals('importModal');
        this.importInProgress = true;
        Api.uploadFile(file, false)
          .then((response) => {
            this.importInProgress = false;
            this.importData = response.data;
            this.importDataModal = true;
            this.nn++;
          })
          .catch(() => {
            Constants.alert.fire('Импорт', 'Ошибка при импорте данных.', 'error');
            this.importInProgress = false;
          });
      },

      closeAndReload() {
        this.closeModals('importDataModal');
        this.loadPage();
      },

      clearSelection() {
        this.selectedIds = [];
      },

      showModifiedRangeModal() {
        this.modifiedRangeData.showModal = false;
        this.$nextTick(() => {
          this.modifiedRangeData.showModal = true;
        });
      },

      showRegistry() {
        this.closeModifiedModal();
        this.onBackChangesOverPeriod();
        this.loadPage();
      },

      onBackChangesOverPeriod() {
        this.request.pageNumber = 1;
        this.closeChangeLog();
      },

      closeModifiedModal() {
        this.modifiedRangeData.isActive = false;
        this.modifiedRangeData.showModal = false;
        this.modifiedRangeData.filter = null;
        this.modifiedRangeData.filterText = null;
        if (this.$refs.modifiedRangeDataModal) this.$refs.modifiedRangeDataModal.reset();
      },

      filterModified(filter, filterText) {
        this.modifiedRangeData.isActive = true;
        this.modifiedRangeData.showModal = false;
        if (!this.changeLog.isActive) {
          this.modifiedRangeData.filter = filter;
          this.modifiedRangeData.filterText = filterText;
        }
        this.onBackChangesOverPeriod();
        this.loadPage();
      },

      async bulkEditingChange(data) {
        const res = await this.changeParametersMulti({
          ...data,
          ids: this.selectedIds,
        });
        this.closeModals('bulkEditingModal');
        this.titleModal = MODAL_INFO_MESSAGES.SAVE_TITLE;
        if (res.success) {
          this.textModal = MODAL_INFO_MESSAGES.SAVE_TEXT;
          this.modalIconName = Constants.MODAL_INFO_ICON.ACCEPT;
          this.isOpenModalInfo = true;
          this.loadPage();
        } else {
          this.textModal = MODAL_INFO_MESSAGES.ERROR_TEXT;
          this.modalIconName = Constants.MODAL_INFO_ICON.ERROR;
          this.isOpenModalInfo = true;
        }
      },

      bulkEditing() {
        this.bulkEditingModal = true;
      },

      editView() {
        this.editViewModal = true;
      },

      changeSorting(sort) {
        const { sortColumn, sortOrder } = sort;
        this.request.sortOrder = sortOrder;
        this.request.sortColumn = sortColumn;
        this.loadPage();
      },

      changeFilter(filter) {
        this.request.pageNumber = 1;
        this.filter = filter;
        this.loadPage();
      },

      clearFilter() {
        this.filter = {};
        this.$refs.headerTable.clearFilter();
        this.loadPage();
      },

      changeSelectAll(value) {
        if (value) {
          this.selectedIds = [];
          this.selectedIds.push(...this.page.items.map((x) => x.id));
        } else {
          this.clearSelection();
        }
      },

      changeColumnTable(array) {
        this.updateListTableHeaderSingle(array);
        this.titleModal = MODAL_INFO_MESSAGES.SAVE_TITLE;
        this.textModal = MODAL_INFO_MESSAGES.SAVE_TEXT;
        this.modalIconName = Constants.MODAL_INFO_ICON.ACCEPT;
        this.closeModals('editViewModal');
        this.isOpenModalInfo = true;
      },

      async loadChangeLog(append) {
        const dataFilter = this.objectFormatter(this.changeLog.filter);
        const res = await this.findAuditLog({
          entityId: this.changeLog.id,
          ...this.request,
          ...dataFilter,
        });
        if (res.success) {
          if (!append) {
            this.page = res.data;
          } else {
            this.page.pageNumber = res.data.pageNumber;
            this.page.items = this.page.items.concat(res.data.items);
          }
          this.changeLog.isActive = true;
          this.isLoading = false;
        } else {
          Constants.alert.fire(Constants.errorTitle, Constants.commonError, 'error');
        }
      },

      async updateMemory(item) {
        const data = {
          ...item.entityDto,
          id: this.changeLog.id,
        };
        if (data.created) delete data.created;
        const res = await this.update(data);
        if (res.success) {
          this.loadChangeLog();
        } else {
          Constants.alert.fire('Сохранение', Constants.commonError, 'error');
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default/table/table-base.scss';
</style>
